import React from "react";
import "../styles/pages/Politics.css"

const Politics = () => {
    return(
        <section>
            <h1 id="rubric">Vår Politik</h1>

            <h2 className="subRubric">12 timmars arbetsdag</h2>
            <div className="whyList">
                <ul>
                    <li><p>Mer tid på arbetsplaterna kommer leda till starkare tillväxt.</p></li>
                    <li><p>Hushållen kommer tjäna mer och kvinnor kommer kunna avstå från att delta på arbetsmarknaden.</p></li>
                    <li><p>Män kommer vara sysselsatta och genom arbetet få ingå i en grupp av goda män. 
                    Detta kommer i sin tur leda till färre grovt kriminella både på våra torg och i våra hem.</p></li>
                </ul>
            </div>

            <h2 className="subRubric">Gratis gymkort till alla <u>MÄN</u></h2>
            <div className="whyList">
                <ul>
                    <li><p>Enligt ordspråken: <br/>
                        <i>"Strong men creates good times"</i> <br/> 
                        <i>"Strong body, strong mind"</i>
                    </p></li>
                </ul>
            </div>
        </section>
    );
}

export default Politics;