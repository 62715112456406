import "./App.css";
import Header from "./components/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Election from "./pages/Election";
import Politics from "./pages/Politics";
import Member from "./pages/Member";
import NoPage from "./pages/NoPage";

function App() {
  return (
    <div className="App">
      <Header />
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="val2022" element={<Election />} />
          <Route path="medlem" element={<Member />} />
          <Route path="politik" element={<Politics />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
