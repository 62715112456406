import React from "react";
import "../styles/pages/Home.css"

const Home = () => {
    return( 
    <div>
        <h1>Home page</h1>
    </div>
    );
}

export default Home;