import React, { useEffect, useState } from "react";
import "../styles/components/Header.css"




const Header = () => {

    const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

    if(windowSize.innerWidth > 1000){
        return(
            <header>
                <div id="containerHeader">
                    <a href="/" className="section">
                        <p id="logoTxt">Manligt Initiativ</p>
                    </a>
                    <a href="/" className="section">
                        <p className="navTxt">Hem</p>
                    </a>
                    <a href="/politik" className="section">
                        <p className="navTxt">Vår Politk</p>
                    </a>
                    <a href="val2022" className="section">
                        <p className="navTxt">Valet 2022</p>
                    </a>
                    <a href="medlem" className="section">
                        <p className="navTxt">Bli Medlem</p>
                    </a>
                </div>
            </header>);
        }
    else{
        return(
            <header>
                <div id="containerHeader">
                    <a href="/" className="section">
                        <p id="logoTxt">Manligt Initiativ</p>
                    </a>
                </div>
            </header>
        )
    }
}

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

export default Header;