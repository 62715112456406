import React from "react";
import "../styles/pages/NoPage.css"
import TateImg from "../assets/tate.png"


const NoPage = () => {
    return(
        <div id="containerNoPage">
            <div id="textSection">
                <p id="bugai">What color is your Bugatti?</p>
            </div>
            <img id="tate" src={TateImg}/>
        </div>
    );
}

export default NoPage;